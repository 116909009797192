import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import {
  mainBlack,
  mainWhite,
  screen,
  whileInVew,
  transHover,
} from "../variables/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { toSlug } from "../utils/helpers";
import FlipMove from "react-flip-move";

const Wrapper = styled.div`
  .item-list {
    @media ${screen.xsmall} {
      display: flex;
      flex-wrap: wrap;
      justify-content: ${({ center }) => (center ? "center" : "flex-start")};
      margin: 0 -15px;
    }

    &__each {
      border-radius: 12px;
      box-shadow: 0 3px 14px rgba(30, 30, 30, 0.15);
      padding: 26px;
      margin: 0 0 26px 0;
      color: ${mainBlack};
      display: flex;
      flex-direction: column;
      overflow: hidden;
      @media ${screen.xsmall} {
        padding: 36px;
        width: calc(50% - 30px);
        margin: 0 15px 30px 15px;
      }
      @media ${screen.small} {
        width: calc(33.33% - 30px);
      }

      .img-wrap {
        border-radius: 9px;
        overflow: hidden;
      }

      img {
        transition: ${transHover};
        display: block;
        width: 100%;
      }

      button {
        border: 1px ${mainBlack} solid;
        background-color: transparent;
        padding: 7px 16px;
        border-radius: 8px;
        color: ${mainBlack};
        height: auto;
        margin: auto 0 0 0;
        color: ${mainBlack};
        line-height: 30px;
        cursor: pointer;
        font-weight: 500;
        transition: ${transHover};
        max-width: max-content;
      }

      .subheading {
        font-weight: 700;
        font-size: 1rem;
        margin: 17px 0 10px 0;
        line-height: 1.45;
        text-transform: uppercase;
        @media ${screen.xsmall} {
          font-size: 1.1rem;
          margin: 22px 0 10px 0;
        }
      }

      .date {
        font-weight: 500;
        margin: 0 0 10px 0;
        display: block;
      }

      .description {
        &--insight {
          margin: 0 0 15px 0;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
          }
        }
      }

      &--article {
        @media ${screen.withCursor} {
          &:hover img {
            transform: scale(1.125);
          }

          &:hover button {
            background: ${mainBlack};
            border: 1px ${mainBlack} solid;
            color: ${mainWhite};
          }
        }
      }
    }
  }
`;

const CardList = ({ items, center, isArticles }) => {
  if (isArticles) {
    return (
      <Wrapper role="listbox">
        <FlipMove className="item-list">
          {items &&
            items.map((item, i) => (
              <Link
                to={`/insights/${toSlug(item.title)}`}
                key={item.id + i}
                className="item-list__each item-list__each--article"
              >
                <div className="img-wrap">
                  <GatsbyImage
                    alt={item.alt || `Thumbnail`}
                    image={item.image}
                  />
                </div>

                <h3 className="subheading">{item.title}</h3>
                <span className="date">{item.date}</span>
                <div
                  className="description description--insight"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
                <button>READ MORE</button>
              </Link>
            ))}
        </FlipMove>
      </Wrapper>
    );
  }

  return (
    <Wrapper center={center} role="listbox">
      <div className="item-list">
        {items.map((item) => (
          <motion.div
            {...whileInVew}
            className="item-list__each"
            role="listitem"
            key={item.heading.text}
          >
            <GatsbyImage
              alt={item.alt || `Thumbnail`}
              image={item.image.thumbnails.cropped.gatsbyImageData}
            />
            <h3 className="subheading">{item.heading.text}</h3>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: item.description.html }}
            />
          </motion.div>
        ))}
      </div>
    </Wrapper>
  );
};

export default CardList;
